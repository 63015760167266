import React, { Fragment } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

// Types
import { User } from "@types";

interface Props {
    systemAvailabilityId: string;
    message: string;
    active: boolean;
    blockSystemUse: boolean;
    children: React.ReactNode;
    user: User;
    loading: boolean;
    notBlocked: boolean;
}

const SystemAvailability: React.FC<Props> = ({
    message,
    active,
    blockSystemUse,
    children,
    user,
    loading,
    notBlocked,
}) => {
    if (notBlocked) return children;

    if (loading) {
        return (
            <Box
                height={"90vh"}
                justifyContent="center"
                display="flex"
                alignItems="center"
                width={"100%"}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!user) {
        //navigate("/401");
        return null;
    }

    if (user?.role === "SCP.Admin" || notBlocked) {
        return (
            <Fragment>
                {active && (
                    <Box textAlign={"center"} my={1}>
                        <Typography variant="h4" component="h4">
                            {message}
                        </Typography>
                    </Box>
                )}
                {children}
            </Fragment>
        );
    }

    return (
        <Fragment>
            {!blockSystemUse && active && (
                <Box textAlign={"center"} my={1}>
                    <Typography variant="h4" component="h4">
                        {message}
                    </Typography>
                </Box>
            )}

            {children}
        </Fragment>
    );
};

export default React.memo(SystemAvailability);
