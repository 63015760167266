// lib
import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useRef, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Location } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";

// own containers/components
import { Sidebar, SystemAvailability, SystemTimer } from "@components";
import { Notifications } from "@containers";

//import footer img
/* import footer from "../../images/footer.svg";
import smartContractingLogo from "@images/smart-contracting.svg"; */

//recoil atoms
import { userState } from "@atoms";

// style
import * as style from "./style.module.scss";
import classNames from "classnames";
import { isSuccessfulCall, pathname } from "../../utils";

// Own hooks
import { useSystemAvailability, useUser } from "@hooks";
import { navigate } from "gatsby";

/**
 * Props type
 */
interface Props {
    children: ReactNode;
}

const Layout = ({ children }: Props) => {
    const { getUser, loading: fetchingUserInfo } = useUser();
    const {
        getSystemInfo,
        loading: fetchingSystemInfo,
        response: systemMaintenance,
    } = useSystemAvailability();

    const ref = useRef<HTMLDivElement>(null);

    const user = useRecoilValue<any>(userState);

    const isInactive =
        pathname?.includes("logout") || pathname?.includes("inactive");

    /**
     * Check if the system is available and the user is logged in
     * If the system is not available, redirect to the system availability page
     * If the user is not logged in, do not redirect
     * If the user is logged in and the system is available, redirect to the home page
     */
    useEffect(() => {
        if (user || isInactive) return;

        Promise.all([getSystemInfo(), getUser()]).then(
            ([sysInfoResponse, userInfoResponse]) => {
                if (
                    isSuccessfulCall(sysInfoResponse?.status) &&
                    isSuccessfulCall(userInfoResponse?.status)
                ) {
                    if (
                        userInfoResponse?.data &&
                        userInfoResponse?.data?.role === "SCP.Admin"
                    )
                        return;

                    if (sysInfoResponse?.data?.blockSystemUse === true) {
                        navigate("/system-availability", {
                            state: { ...sysInfoResponse?.data },
                            replace: true,
                        });
                        return;
                    }
                    if (pathname?.includes("system-availability")) {
                        navigate("/");
                        return;
                    }
                }
            },
        );
    }, [user, isInactive]);

    /**
     * Memoized footer renderer
     */
    const footerRenderer = useMemo(() => {
        return (
            <div className={style.footer}>
                <div className={style.wrapper}>
                    <StaticImage
                        src={"../../images/footer.svg"}
                        alt="footer"
                        placeholder="blurred"
                    />

                    <StaticImage
                        // no absolute path https://github.com/gatsbyjs/gatsby/discussions/35667
                        src={"../../images/smart-contracting.svg"}
                        alt="Smart contracting logo"
                        objectFit="contain"
                        placeholder="blurred"
                    />
                </div>
            </div>
        );
    }, []);

    /**
     * Render
     */
    return (
        <div className={style.layout}>
            <Notifications />

            <Box className={style.body} ref={ref}>
                <SystemTimer itemRef={ref} />

                <Location>
                    {({ location }) =>
                        !!user &&
                        !fetchingUserInfo &&
                        !fetchingSystemInfo &&
                        (!systemMaintenance?.blockSystemUse ||
                            (systemMaintenance?.blockSystemUse &&
                                user?.role === "SCP.Admin")) && (
                            <div className={style.sidebar}>
                                <Sidebar
                                    id="layout"
                                    user={user}
                                    location={location}
                                />
                            </div>
                        )
                    }
                </Location>

                <Box
                    className={classNames(style.content, {
                        [style.fullWidth]: !user,
                        [style.noScroll]:
                            (systemMaintenance?.blockSystemUse &&
                                user?.role !== "SCP.Admin") ||
                            fetchingUserInfo ||
                            fetchingSystemInfo,
                    })}
                    tabIndex={0}
                >
                    <SystemAvailability
                        {...systemMaintenance}
                        user={user}
                        loading={fetchingUserInfo || fetchingSystemInfo}
                        notBlocked={isInactive}
                    >
                        {children}
                    </SystemAvailability>
                </Box>

                {footerRenderer}
            </Box>
        </div>
    );
};

export default React.memo(Layout);
