//Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

//Own components
import { ContractPartnersForm, FormFooter } from "@components";

//Custom hooks
import { useContractPartners, useUpdateStep } from "@hooks";

//Atoms
import {
    contractPartnersState,
    financialConditionsState,
    generalInformationState,
} from "@atoms";

//Schemas
import { contractPartnersSchema } from "@schemas";

// types
import type { FlowStep, Location, Partner } from "@types";
import { isArrayWithContent, isObjectWithContent } from "@utils";
import * as Yup from "yup";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    id?: string;
    flowState?: Array<FlowStep>;
}

/**
 * Additional Partners
 */
const AdditionalPartners = React.forwardRef(
    ({ location, flowState, currentStep, id }: Props, ref) => {
        // primary partner
        const generalInformation: any = useRecoilValue(generalInformationState);
        const financialConditions: any = useRecoilValue(
            financialConditionsState,
        );

        /**
         * API
         */
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        /**
         * Contract Partners State
         */
        const [contractPartners, updateContractPartnersState] = useRecoilState(
            contractPartnersState,
        );

        /**
         * update step hook
         */
        const updateStepValidation = useUpdateStep(location, flowState);

        const mapPartnerToAccount = useMemo(() => {
            if (!isArrayWithContent(contractPartners)) return [];
            return contractPartners.map((el: Partner) => {
                return {
                    accountId: el.id,
                    accountName: el.name,
                    accountStatus: el.status,
                };
            });
        }, [contractPartners]);
        /**
         * Formik state
         */

        const formik = useFormik({
            initialValues: mapPartnerToAccount,
            enableReinitialize: true,
            validateOnMount: true,
            // Manual validation using Yup with context

            validate: async values => {
                const shouldAddAdditionalPartners =
                    !!financialConditions?.conditions["apheresisVat"] ||
                    !!financialConditions?.conditions["apheresisNoVat"];
                try {
                    // Validate asynchronously using Yup's validate method, passing the context
                    await contractPartnersSchema.validate(values, {
                        context: { shouldAddAdditionalPartners },
                    });
                } catch (error) {
                    if (error instanceof Yup.ValidationError) {
                        return { [error.path as string]: error.message }; // Return error object for Formik
                    }
                }

                return {}; // No validation errors
            },

            onSubmit: () => undefined,
        });

        const disabled = useMemo(() => {
            if (!isArrayWithContent(formik.values)) return false;

            return formik.values?.some(el => !el.accountId);
        }, [formik.values]);

        /**
         * Form validation check
         */

        const hasErrors = useMemo(() => {
            if (!formik) return;
            return disabled || !!isObjectWithContent(formik.errors);
        }, [formik?.errors]);

        /**
         * Check if the current step is valid => set isPrepared to true, otherwise set it false
         */

        useEffect(() => {
            // TODO: fix the weird error that the hook throws when we don't wrap it with this condition
            if (currentStep) {
                updateStepValidation(!hasErrors);
            }
        }, [hasErrors]);

        /**
         * Save and go to the next page
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                const mappedPartners: Partner[] = !isArrayWithContent(
                    formik.values,
                )
                    ? []
                    : formik.values.map(el => {
                          return {
                              id: el.accountId,
                              name: el.accountName,
                              status: el.accountStatus,
                          };
                      });
                updateContractPartnersState(mappedPartners);
            },
        }));

        return (
            <Box
                display="flex"
                flexDirection="column"
                height={1}
                justifyContent="space-between"
            >
                <Box mb={2}>
                    <Typography variant="h2" mb={2}>
                        Additional partners
                    </Typography>

                    <ContractPartnersForm
                        id={`${id}-contract-partner-form`}
                        partners={{
                            data: partners?.data?.partners,
                            loading: partnersLoading,
                            onSearch: (query: string) => getPartners(query),
                        }}
                        values={formik.values}
                        onChange={formik.setValues}
                        errors={formik.errors}
                        touched={formik.touched}
                        onBlur={formik.handleBlur}
                        //setTouched={formik.setTouched}
                        primaryPartnerId={
                            generalInformation?.primaryPartner?.accountId
                        }
                    />
                </Box>
                <FormFooter
                    id={`${id}-contract-footer`}
                    error={!!hasErrors}
                    textAlign="right"
                    showText={isArrayWithContent(formik.values)}
                />
            </Box>
        );
    },
);
export default AdditionalPartners;
