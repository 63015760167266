import React, { useEffect, useState } from "react";
import {
    Switch,
    TextField,
    FormControlLabel,
    Grid,
    Typography,
    InputLabel,
    CircularProgress,
    Button,
    FormHelperText,
} from "@mui/material";

// Own components
import { LoadingWrapper } from "@components";

// Own hooks
import { useMaintenanceSystemAvailability } from "@hooks";

/**
 * System availability
 */
const SystemAvailability: React.FC = () => {
    const {
        upsert,
        sysInfo,
        loading: { creating, fetching },
    } = useMaintenanceSystemAvailability();

    const [isActiveMaintenance, setIsActiveMaintenance] = useState(false);
    const [isBlockSystem, setIsBlockSystem] = useState(false);
    const [inputText, setInputText] = useState("");

    const [error, setError] = useState<string | null>(null);

    const handleActiveMaintenanceChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIsActiveMaintenance(event.target.checked);
    };

    const handleBlockSystemChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIsBlockSystem(event.target.checked);
    };

    const handleInputTextChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setInputText(event.target.value);
    };

    useEffect(() => {
        if (sysInfo?.data) {
            setIsActiveMaintenance(sysInfo?.data?.active || false);
            setIsBlockSystem(sysInfo?.data?.blockSystemUse || false);
            setInputText(sysInfo?.data?.message || "");
        }
    }, [sysInfo]);

    const onSubmit = () => {
        if ((isActiveMaintenance || isBlockSystem) && !inputText) {
            setError("Please provide a message");
            return;
        }
        setError(null);
        upsert({
            systemAvailabilityId: sysInfo?.systemAvailabilityId || undefined,
            active: isActiveMaintenance,
            blockSystemUse: isBlockSystem,
            message: inputText,
        });
    };

    return (
        <LoadingWrapper
            id={`maintenance-system-availability`}
            loading={fetching}
        >
            <Grid container item xs={12} rowSpacing={2}>
                <Grid item xs={12} lg={7}>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={isActiveMaintenance}
                                onChange={handleActiveMaintenanceChange}
                                name="activeMaintenance"
                            />
                        }
                        label={
                            <Typography variant="subtitle2">
                                Active Maintenance Mode
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={isBlockSystem}
                                onChange={handleBlockSystemChange}
                                name="blockSystem"
                            />
                        }
                        label={
                            <Typography variant="subtitle2">
                                Block System use
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={8} mt={3}>
                    <InputLabel shrink error={!!error}>
                        System Maintenance Message
                    </InputLabel>
                    <TextField
                        value={inputText}
                        onChange={handleInputTextChange}
                        fullWidth
                        multiline
                        minRows={6}
                        autoComplete="off"
                        size="small"
                        error={!!error}
                    />
                    <FormHelperText error={!!error}>{error}</FormHelperText>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={8}
                    mt={5}
                    container
                    justifyContent={"flex-end"}
                >
                    <Button
                        id={`maintenance-save-system-info`}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        size="medium"
                        disabled={creating || fetching}
                    >
                        {creating ? (
                            <CircularProgress size={25} color="inherit" />
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Grid>
            </Grid>
        </LoadingWrapper>
    );
};

export default SystemAvailability;
