//import { useApi } from "../api";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "@atoms";
import { useAxios } from "./useAxios";
import { isSuccessfulCall } from "@utils";
import { NOTIFICATIONS } from "@constants";
import type { Api, NotificationSeverity } from "@types";

const BASE_URI = `/v0`;

export const useSystemAvailability = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getSystemInfo: () =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/system`,
                },
                { errorHandler: "Failed to fetch system info" },
            ),
        error,
        loading,
        response: response?.data,
    };
};

export const useMaintenanceSystemAvailability = () => {
    const url = `${BASE_URI}/maintenance/availability`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response: sysInfo,
        loading: fetching,
        fetch: load,
        error: error,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        {
            errorHandler: `Failed to fetch system info`,
        },
    );

    const { loading: creating, fetch: create }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        {
            errorHandler: `Failed to update system info`,
        },
    );

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            load();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        sysInfo,
        loading: {
            creating,
            fetching,
        },
        error,
        getSystemInfo: () => load(),
        upsert: async (sysInfo: any) =>
            await create({
                data: sysInfo,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `System info was successfully updated`,
                        autoHide: true,
                    });

                    load();
                }
                return res;
            }),
    };
};
