// Lib
import { Router } from "@reach/router";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";

// Hooks
import { usePermission, useLandingPage, useSelectedCountry } from "@hooks";

// Atoms
import { teamState, userState } from "@atoms";

//Types
import { Location, Permissions, User } from "@types";

/**
 * Props
 */
interface Props {
    location: Location;
}

/*
 *  Wrapper for page routes to check if the user is authenticated or not
 *  If  authenticated && user data present: render the page
 */
const PrivateRoute = ({ page: Page, location, ...rest }: any) => {
    const user = useRecoilValue<User | undefined>(userState);
    const team = useRecoilValue(teamState);
    const { isGermanyTeam, isUkTeam } = useSelectedCountry();

    /**
     * API
     */

    /**
     * Permissions
     */
    // Check if the user has at least one right
    const { isUserWithRights } = useLandingPage();

    //Contracts
    const {
        hasPermissionToReadContract,
        hasPermissionToCreateContract,
        hasPermissionToEditContract,
        hasPermissionToReadFinancialActions,
        hasPermissionToEditFinancialActions,
        hasPermissionToReadOrder,
        hasPermissionToEditOrder,
        hasPermissionToEditOutcome,
        hasPermissionToReviewContract,
        hasPermissionToApproveFinancialAction,
        hasPermissionToEditCamundaConfiguration,
        hasPermissionToEditMaintenance,
        hasPermissionToEditDataTables,
        hasPermissionToEditClaim,
        hasPermissionToReadClaim,
    }: Permissions = usePermission();

    // No permission (has an access but is not a member of any team)
    const hasTotallyNoPermission = useMemo(() => {
        return !isUserWithRights && !hasPermissionToEditMaintenance && !team;
    }, [isUserWithRights, hasPermissionToEditMaintenance, team]);

    /**
     * Private routes permission
     */
    const routesPermissions = [
        // Dashboard
        {
            matchFragment: /dashboard\/contracts/,
            permission:
                (!!team && hasPermissionToReadContract) ||
                hasPermissionToCreateContract ||
                hasPermissionToEditContract,
        },
        {
            matchFragment: /dashboard\/orders/,
            permission: !!team && hasPermissionToReadOrder,
        },
        {
            matchFragment: /dashboard\/outcomes/,
            permission: !!team && hasPermissionToEditOutcome,
        },
        {
            matchFragment: /dashboard\/finances\/bpf/,
            permission:
                (!!team && hasPermissionToReadFinancialActions) ||
                hasPermissionToEditFinancialActions,
        },
        {
            matchFragment: /dashboard\/finances\/generate-bpf/,
            permission: !!team && hasPermissionToEditFinancialActions,
        },
        {
            matchFragment: /dashboard\/finances\/credit-note-claim/,
            permission: !!team && isUkTeam,
        },
        {
            matchFragment: /dashboard\/claims/,
            permission:
                !!team &&
                (hasPermissionToEditClaim || hasPermissionToReadClaim),
        },
        {
            matchFragment: /dashboard\/price-correction-claims/,
            permission:
                !!team &&
                (hasPermissionToEditClaim || hasPermissionToReadClaim) &&
                isGermanyTeam,
        },

        {
            matchFragment: new RegExp(
                /claims\/claim-details\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission:
                !!team &&
                (hasPermissionToEditClaim || hasPermissionToReadClaim),
        },
        {
            matchFragment: /dashboard\/products/,
            permission: !!team,
        },
        {
            matchFragment: new RegExp(
                /product\/external-codes\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission: !!team && hasPermissionToEditDataTables,
        },
        {
            matchFragment: /dashboard\/partners/,
            permission: !!team,
        },
        {
            matchFragment: new RegExp(
                /partner\/edit-partner\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission: !!team && hasPermissionToEditDataTables,
        },
        {
            matchFragment: /partner\/create-partner/,
            permission: !!team && hasPermissionToEditDataTables,
        },
        {
            matchFragment: new RegExp(
                /partner\/external-codes\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission: !!team && hasPermissionToEditDataTables,
        },
        {
            matchFragment: new RegExp(
                /region\/external-codes\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission: !!team && hasPermissionToEditDataTables,
        },
        {
            matchFragment: /contract\/view-contract/,
            permission: !!team && hasPermissionToReadContract,
        },
        {
            matchFragment: /create-contract/,
            permission: !!team && hasPermissionToCreateContract,
        },
        // Maintenance
        {
            matchFragment: /maintenance\/product/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/partner/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/country/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/teams/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/interface/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/billing-plan/,
            permission: hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /maintenance\/milestone/,
            permission: hasPermissionToEditMaintenance,
        },

        {
            matchFragment: new RegExp(
                /contract-team\/edit-team\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission: hasPermissionToEditMaintenance,
        },

        {
            matchFragment: /finances\/credit-note-details/,
            permission:
                (!!team && hasPermissionToEditFinancialActions) ||
                hasPermissionToReadFinancialActions,
        },
        {
            matchFragment: /finances\/invoice-release-details/,
            permission:
                (!!team && hasPermissionToEditFinancialActions) ||
                hasPermissionToReadFinancialActions,
        },
        {
            matchFragment: /tasks\/ongoing-tasks/,
            permission:
                !!team &&
                (!!hasPermissionToEditDataTables ||
                    hasPermissionToReviewContract ||
                    hasPermissionToApproveFinancialAction ||
                    hasPermissionToEditCamundaConfiguration ||
                    hasPermissionToEditOrder),
        },
        {
            matchFragment: /tasks\/completed-tasks/,
            permission:
                !!team &&
                (!!hasPermissionToEditDataTables ||
                    hasPermissionToReviewContract ||
                    hasPermissionToApproveFinancialAction ||
                    hasPermissionToEditCamundaConfiguration ||
                    hasPermissionToEditOrder),
        },
        {
            matchFragment: new RegExp(
                /tasks\/task-details\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/,
            ),
            permission:
                !!team &&
                (!!hasPermissionToEditDataTables ||
                    hasPermissionToReviewContract ||
                    hasPermissionToApproveFinancialAction ||
                    hasPermissionToEditCamundaConfiguration ||
                    hasPermissionToEditOrder),
        },
        {
            // Should be visible to SCP user with at least one right
            matchFragment: /reports/,
            permission: isUserWithRights,
        },
        {
            matchFragment: /account/,
            permission: !!team || hasPermissionToEditMaintenance,
        },
        {
            matchFragment: /process/,
            permission: !!team && hasPermissionToEditClaim,
        },
        {
            /**
             * if the user has totally no permission he should see access denied page
             */
            matchFragment: /\//,
            permission: !hasTotallyNoPermission,
        },
    ];

    /**
     * Check if the user allowed to navigate to the route
     */
    const isAllowedRoute = pathname => {
        const routeMatch = routesPermissions.find(item =>
            item.matchFragment.test(pathname),
        );
        return routeMatch ? routeMatch.permission : true;
    };

    if (!Page)
        throw new ReferenceError("Private route page component is undefined!");

    const createComponent = () => <Page location={location} {...rest} />;

    if (user) {
        if (
            (!!team || !!hasPermissionToEditMaintenance) &&
            !isAllowedRoute(location.pathname)
        ) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width={1}
                    height={"90vh"}
                >
                    <Box mt={4} mb={4}>
                        <LockIcon
                            color="primary"
                            sx={{
                                maxWidth: "7.5rem",
                                maxHeight: "7.5rem",
                                fontSize: "7.5rem",
                            }}
                        />
                    </Box>
                    <Box mb={3}>
                        <Typography variant="h3">Access denied</Typography>
                    </Box>
                    <Box mb={4}>
                        <Typography variant="subtitle2">
                            {
                                "You don't have a permission to access this page, please contact your administrator."
                            }
                        </Typography>
                    </Box>
                </Box>
            );
        }

        if (!team || !!isAllowedRoute(location.pathname)) {
            // Check if the user has already selected team
            // if not => navigate to select team page

            if (
                !team &&
                (!hasPermissionToEditMaintenance ||
                    (hasPermissionToEditMaintenance &&
                        user?.numberOfTeams > 0 &&
                        !location.pathname.includes("/maintenance") &&
                        !location.pathname.includes("/contract-team"))) &&
                !location.pathname.includes("/select-team")
            ) {
                window.location.href = "/select-team/";
                return null;
            }
            return createComponent();
        }
    }

    return null;
};
export default PrivateRoute;

/*
 *   The utility method to wrap any page in a private route
 */
export const withPrivateRoute =
    (Page: any, intendedPath?: Location | string) => (props: Props) => (
        <Router>
            <PrivateRoute
                page={Page}
                path={intendedPath ? intendedPath : props.location.pathname}
            />
        </Router>
    );
